<template>
  <v-container id="container" fluid>
    <v-row align="center" justify="center" class="mt-10" style="height: 250px">
      <div class="text-center">
        <div class="text-center">
          <v-img
            id="imgnotfound"
            min-width="300"
            max-width="650"
            class="ml-4"
            :src="notfound"
          ></v-img>
          <h3>Ops! A página que você está tentando acessar não existe.</h3>
        </div>
      </div>
    </v-row>
    <v-row id="btn" align="end" justify="center" class="mt-10">
      <v-btn elevation="5" @click="$router.go(-1)" color="accent">
        Voltar para a página anterior
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageNotFound",

  data: () => ({
    notfound: require("@/assets/images/404notfound.png"),
  }),

  methods: {},
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Press+Start+2P&display=swap");
#btn {
  height: 50% !important;
}

h3 {
  font-family: "Open Sans", sans-serif;
  color: rgb(49, 31, 80);
  margin: 30px;
}

#container {
  background: rgb(49, 31, 80);
  background: linear-gradient(
    106deg,
    rgba(49, 31, 80, 0.6628852224483543) 0%,
    rgba(255, 255, 255, 1) 48%
  );
  height: 100%;
  max-height: max-content;
}

#imgnotfound {
  animation: myAnim 2s linear 0s infinite normal forwards;
  margin: auto !important;
}

@keyframes myAnim {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

@media (max-width: 650px) {
  #imgnotfound {
    width: 480px !important;
  }
  h3 {
    font-size: larger !important;
  }
}

@media (max-width: 450px) {
  #imgnotfound {
    width: 330px !important;
  }
  h3 {
    font-size: medium !important;
  }
}
</style>
